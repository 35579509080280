<template>
  <b-row>
    <vue-confirm-dialog />
    <b-col>
      <b-card>
        <b-row>
          <b-col md="11">
            <h2>
              Mensagens
            </h2>
            <h6>
              Crie e/ou gerencie as mensagens do sistema
            </h6>
          </b-col>
          <b-col md="1">
            <b-button
              v-b-popover.hover.left="'Crie ou gerencie as mensagens que aparecem na tela inicial dos usuarios do seu sistema'"
              class="btn-icon"
              variant="flat-dark"
              pill
            >
              <feather-icon
                icon="MessageSquareIcon"
                size="40"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-row>
          <b-col md="12">
            <h3>
              Adicione uma mensagem
            </h3>
          </b-col>
          <b-col
            md="4"
          >
            <b-form-group
              label="Titulo da mensagem : *"
              label-for="titulo"
            >
              <b-form-input
                id="titulo"
                v-model="titulo"
                placeholder="Titulo da mensagem"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="4"
          >
            <b-form-group
              label="Valida até : *"
              label-for="validade"
            >
              <b-form-datepicker
                id="validade"
                v-model="validade"
                :date-format-options="{ month: 'numeric', year: 'numeric', day: 'numeric' }"
                locale="br"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="4"
          >
            <b-form-group
              label="Usuarios *"
              label-for="usuarios"
            >
              <v-select
                id="usuarios"
                v-model="usuarios"
                label="text"
                placeholder="Selecione os usuarios"
                :options="opcoesuser"
              >
                <template #option="{ text, icon }">
                  <feather-icon
                    :icon="icon"
                    size="16"
                    class="align-middle mr-50"
                  />
                  <span> {{ text }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Mensagem : *"
              label-for="mensagem"
            >
              <b-form-textarea
                id="mensagem"
                v-model="mensagem"
                placeholder="Mensagem"
                rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-button
              variant="gradient-success"
              block
              @click="VerificaMSG"
            >
              Enviar Mensagem
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-row>
          <b-col md="12">
            <h3>
              Historico de mensagens
            </h3>
          </b-col>
          <b-col md="12">
            <data-table
              :data="data"
              :columns="columns"
              @on-table-props-changed="reloadTable"
            >
              <tbody
                slot="body"
                slot-scope="{
                  // eslint-disable-next-line vue/no-template-shadow
                  data }"
              >
                <tr
                  v-for="item in data"
                  :key="item.id"
                >
                  <td
                    v-for="column in columns"
                    :key="column.name"
                  >
                    <data-table-cell
                      :value="item"
                      :name="column.name"
                      :meta="column.meta"
                      :comp="column.component"
                      :classes="column.classes"
                    />
                    <slot v-if="column.label === 'Desativar'">
                      <b-button
                        style="margin-left: 30%"
                        variant="outline-danger"
                        size="sm"
                        class="btn-icon rounded-circle"
                        @click="DeletarDados(item)"
                      >
                        <feather-icon icon="PowerIcon" />
                      </b-button>
                    </slot>
                    <slot v-if="column.label == 'Status'">
                      <b-badge
                        style="margin-left: 20%"
                        :variant="NomeStatus(item).cor"
                      >
                        {{ NomeStatus(item).msg }}
                      </b-badge>
                    </slot>
                  </td>
                </tr>
              </tbody>
            </data-table>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Notificacoes',
  components: {
  },
  data() {
    return {
      url: 'mensagens',
      data: {},
      dadositem: {},
      tableProps: {
        search: '',
        length: 10,
        column: 'id',
        dir: 'asc',
      },
      columns: [
        {
          label: 'Usuario',
          name: 'user.name',
          orderable: true,
        },
        {
          label: 'Titulo',
          name: 'titulo',
          orderable: true,
        },
        {
          label: 'Enviado para:',
          name: 'role',
          orderable: true,
        },
        {
          label: 'Validade',
          name: 'validade',
          orderable: true,
        },
        {
          label: 'Status',
          orderable: true,
        },
        {
          label: 'Desativar',
          filterable: false,
        },
      ],
      id: null,
      mensagem: '',
      titulo: '',
      usuarios: '',
      validade: '',
      opcoesuser: [
        {
          id: 1, text: 'Admins', icon: 'LockIcon', slug: 'adm',
        },
        {
          id: 2, text: 'Vendedores', icon: 'ShoppingBagIcon', slug: 'vendedor',
        },
        {
          id: 3, text: 'Caixas', icon: 'DollarSignIcon', slug: 'caixa',
        },
        {
          id: 4, text: 'Todos', icon: 'GlobeIcon', slug: 'todos',
        },
      ],
    }
  },
  created() {
    this.BuscarDados()
  },
  methods: {
    formatarData() {
      this.validade = moment(String(this.validade)).format('DD/MM/YY')
    },
    async VerificaMSG() {
      this.$loading(true)
      const obj = {
        role: this.usuarios.slug,
      }
      await this.$http.post('mensagem_role', obj).then(resp => {
        this.MSG = resp.data
      })
      if (this.MSG.success) {
        this.$loading(false)
        this.$toast.error('Ja existe uma mensagem ativa para esses usuarios')
      }
      if (this.MSG.error) {
        await this.SalvarMensagem()
      }
    },
    async SalvarMensagem() {
      this.formatarData()
      this.$loading(true)
      this.id = JSON.parse(localStorage.getItem('userData')).id
      const obj = {
        user_id: this.id,
        titulo: this.titulo,
        role: this.usuarios.slug,
        mensagem: this.mensagem,
        validade: this.validade,
      }
      this.$http.post('mensagens', obj).then(resp => {
        this.data = resp.data
        if (this.data.success) {
          this.$toast.success('Mensagem enviada com sucesso')
          this.reloadTable()
          this.LimpaCampos()
        } else {
          this.$toast.error('Erro ao enviar mensagem')
        }
      })
    },
    LimpaCampos() {
      this.id = null
      this.titulo = ''
      this.usuarios = ''
      this.mensagem = ''
      this.validade = ''
    },
    NomeStatus(item) {
      if (item.status === 1) {
        return { cor: 'light-success', msg: 'Ativo' }
      }
      if (item.status === 0) {
        return { cor: 'light-danger', msg: 'Inativo' }
      } return 'Sem Status'
    },
    reloadTable(tableProps) {
      this.BuscarDados(this.url, tableProps)
    },
    async BuscarDados(url = this.url, options = this.tableProps) {
      this.$loading(false)
      await this.$http.get(url, { params: options }).then(resp => {
        this.data = resp.data
      })
      this.$loading(false)
    },
    async DeletarDados(item) {
      this.$confirm(
        {
          message: 'Tem certeza que deseja Desativar esta mensagem ?',
          button: {
            no: 'Não',
            yes: 'Sim',
          },
          /**
             * Callback Function
             * @param {Boolean} confirm
             */
          callback: async confirm => {
            if (confirm) {
              this.$loading(true)
              await this.$http.delete(`mensagens/${item.id}`)
              this.$toast.success('Mensagem desativada com sucesso')
              this.reloadTable()
            }
          },
        },
      )
    },
  },
}
</script>

<style scoped>

</style>
